import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../SEO';
import { Footer } from '../components/footer';
import NoScript from '../components/NoScript';
import ButtonShareMobile from '../components/button/ButtonShareMobile';
import BlockText from '../components/blockText/BlockText';
import ButtonShareDesktop from '../components/button/ButtonShareDesktop';
import ButtonCta from '../components/button/ButtonCta';
import Navbar from '../components/navbar';
import { urlFor } from '../utils/common';
import CardPengaduan from '../components/cardPengaduan';
import Wrapper from '../components/Wrapper';
import GTM_TRACKERS from '../../constants/gtm-trackers';
import sanityConfig from '../../config/sanity';

const PromoDetail = ({ pageContext }) => {
  const [thisURL, setThisUrl] = useState('');
  const cardImage = getGatsbyImageData(pageContext.mainImage, {
    placeholder: 'blurred', layout: 'fullWidth', width: 600,
  }, sanityConfig);
  useEffect(() => {
    setThisUrl(window.location.href);
  }, []);
  return (
    <Wrapper>
      <Seo
        lang={pageContext.lang}
        title={pageContext.title}
        metaDescription={pageContext.title}
        metaImage={urlFor(pageContext.mainImage).url()}
        type="website"
        postData={pageContext}
        cannonicalPath={`promo/${pageContext.slug.current}`}
      />
      <Navbar />
      <div className="container mx-auto relative pt-3 lg:pt-20 p-0">
        <div className="hidden absolute md:block lg:block pl-5 my-12">
          <ButtonShareDesktop url={thisURL} />
        </div>
        <div className="mx-auto pt-2 w-11/12 md:w-8/12 lg:w-8/12">
          {/* <div className="my-4 md:my-5 lg:my-5 promo-label text-secondary capitalize">
            <span>{pageContext.category}</span>
          </div> */}
          <div className="text-2xl font-bold md:text-4xl lg:text-4xl">
            <span>{pageContext.title}</span>
          </div>
          <div className="flex lg:my-5">
            <div className="text-sm font-bold text-gray-dark">
              <span>{pageContext.author.name}</span>
            </div>
            <div className="text-sm text-gray-dark ml-1">
              <span>- {pageContext.publishedAt}</span>
            </div>
          </div>
          {pageContext.mainImage !== null
            ? (
              <div className="w-full my-4">
                <Link to={pageContext.mainImageURL} className="hover:no-underline cursor-pointer">
                  <GatsbyImage
                    loading="lazy"
                    image={cardImage}
                    title={pageContext.title}
                    alt={pageContext.title}
                    className="rounded-2xl"
                  />
                </Link>
              </div>
            )
            : (
              null
            )}
          <BlockText className="lg:my-4" body={pageContext._rawBody} title={pageContext.title} promoCode={pageContext.promoCode} />
          <ButtonShareMobile url={thisURL} />
          <div
            className="flex flex-row justify-center w-full mt-10 mb-20"
          >
            {Array.isArray(pageContext.buttonCtas) && pageContext.buttonCtas.map((btn) => (
              <div className={btn.text === 'Mau Promonya!' ? GTM_TRACKERS.PROMO.MAU_PROMONYA : GTM_TRACKERS.PROMO.YUK_DEPOSITO_SEKARANG}>
                <ButtonCta
                  {...btn}
                  promoCode={pageContext.promoCode}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <CardPengaduan />
      <Footer showConnectWithUs={false} />
      <NoScript />
    </Wrapper>
  );
};

PromoDetail.propTypes = {
  pageContext: propTypes.object,
};

PromoDetail.defaultProps = {
  pageContext: {},
};

export default PromoDetail;
